var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "widgets", fluid: "", tag: "section" } },
    [
      _c("base-v-component", {
        attrs: { heading: "Cards", link: "components/cards" }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "base-material-card",
                {
                  staticClass: "px-5 py-3",
                  attrs: { color: "warning", inline: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "heading",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "display-2 font-weight-light" },
                            [
                              _vm._v(
                                "\n            Employees Stats\n          "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "subtitle-1 font-weight-light" },
                            [
                              _vm._v(
                                "\n            New employees on 15th September, 2016\n          "
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-data-table", {
                        attrs: { headers: _vm.headers, items: _vm.items }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "base-material-card",
                {
                  staticClass: "px-5 py-3",
                  scopedSlots: _vm._u([
                    {
                      key: "heading",
                      fn: function() {
                        return [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                "background-color": "transparent",
                                "slider-color": "white"
                              },
                              model: {
                                value: _vm.tabs,
                                callback: function($$v) {
                                  _vm.tabs = $$v
                                },
                                expression: "tabs"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "subheading font-weight-light mx-3",
                                  staticStyle: { "align-self": "center" }
                                },
                                [_vm._v("Tasks:")]
                              ),
                              _c(
                                "v-tab",
                                { staticClass: "mr-3" },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(
                                      "\n                mdi-bug\n              "
                                    )
                                  ]),
                                  _vm._v("\n              Bugs\n            ")
                                ],
                                1
                              ),
                              _c(
                                "v-tab",
                                { staticClass: "mr-3" },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(
                                      "\n                mdi-code-tags\n              "
                                    )
                                  ]),
                                  _vm._v(
                                    "\n              Website\n            "
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-tab",
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(
                                      "\n                mdi-cloud\n              "
                                    )
                                  ]),
                                  _vm._v("\n              Server\n            ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "v-tabs-items",
                    {
                      staticClass: "transparent",
                      model: {
                        value: _vm.tabs,
                        callback: function($$v) {
                          _vm.tabs = $$v
                        },
                        expression: "tabs"
                      }
                    },
                    _vm._l(3, function(n) {
                      return _c(
                        "v-tab-item",
                        { key: n },
                        [
                          _c(
                            "v-card-text",
                            [
                              _vm._l(_vm.tasks[_vm.tabs], function(task, i) {
                                return [
                                  _c(
                                    "v-row",
                                    { key: i, attrs: { align: "center" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-checkbox", {
                                                attrs: { color: "secondary" },
                                                model: {
                                                  value: task.value,
                                                  callback: function($$v) {
                                                    _vm.$set(task, "value", $$v)
                                                  },
                                                  expression: "task.value"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-col", { attrs: { cols: "9" } }, [
                                        _c("div", {
                                          staticClass: "font-weight-light",
                                          domProps: {
                                            textContent: _vm._s(task.text)
                                          }
                                        })
                                      ]),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mx-1" },
                                            [
                                              _vm._v(
                                                "\n                      mdi-pencil\n                    "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mx-1",
                                              attrs: { color: "error" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      mdi-close\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-row",
                [
                  _vm._l(_vm.plans, function(plan, i) {
                    return [
                      _c(
                        "v-col",
                        { key: i, attrs: { cols: "12", md: "6" } },
                        [_c("pages-plan-card", { attrs: { plan: plan } })],
                        1
                      )
                    ]
                  }),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("base-material-testimony", {
                        attrs: {
                          blurb:
                            "Your products, all the kits that I have downloaded from your site and worked with are sooo cool! I love the color mixtures, cards... everything. Keep up the great work!",
                          author: "Alec Thompson",
                          handle: "@alecthompson"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-timeline",
                { attrs: { "align-top": "", dense: "" } },
                _vm._l(_vm.timelines, function(timeline, i) {
                  return _c(
                    "v-timeline-item",
                    {
                      key: i,
                      attrs: {
                        color: timeline.color,
                        icon: timeline.icon,
                        "fill-dot": "",
                        large: ""
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-6" },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "overline mb-3",
                              attrs: { color: timeline.color, small: "" }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(timeline.chip) +
                                  "\n            "
                              )
                            ]
                          ),
                          _c("p", {
                            staticClass: "subtitle-1",
                            domProps: { textContent: _vm._s(timeline.text) }
                          }),
                          _c("div", {
                            staticClass: "text-uppercase body-2",
                            domProps: { textContent: _vm._s(timeline.subtext) }
                          }),
                          timeline.action
                            ? [
                                _c("v-divider", { staticClass: "mb-3" }),
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      bottom: "",
                                      "offset-y": "",
                                      origin: "top left",
                                      right: "",
                                      transition: "scale-transition"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var attrs = ref.attrs
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: timeline.action,
                                                        default: "",
                                                        rounded: ""
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", {
                                                    attrs: { left: "" },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        timeline.actionIcon
                                                      )
                                                    }
                                                  }),
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { right: "" } },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.menu
                                                              ? "mdi-menu-up"
                                                              : "mdi-menu-down"
                                                          ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.menu,
                                      callback: function($$v) {
                                        _vm.menu = $$v
                                      },
                                      expression: "menu"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-sheet",
                                      [
                                        _c(
                                          "v-list",
                                          _vm._l(timeline.actions, function(a) {
                                            return _c(
                                              "v-list-item",
                                              { key: a, attrs: { link: "" } },
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    textContent: _vm._s(a)
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }